import React from 'react';
import logo from '../background.jpeg'
import { Amplify } from 'aws-amplify';
import Header from './header';
import Footer from './footer';
import { useState ,useContext,useRef,useEffect,useCallback} from "react";
import {useForm} from "react-hook-form";
import { useMutation } from "react-query";
import '@aws-amplify/ui-react/styles.css';
import config from '../aws-exports';
import { Theme, Flex, Text, Button, Grid, Box,Container } from '@radix-ui/themes';
import axios from 'axios';
import Axios from 'axios';
import {UserContext} from '../App';
import '@radix-ui/themes/styles.css';
import FloatingBox from './FloatingBox';
import whiteOverlay from './Head-color with text.png';
import TextArea from './TextArea';
import { Component1Icon } from '@radix-ui/react-icons';
import {SpinningCircles,TailSpin} from 'react-loading-icons';
import { toast,Toaster } from 'react-hot-toast';
import './home.css';
Amplify.configure(config);


const GlassesIcon = () => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.5 6.625C1.01675 6.625 0.625 7.01675 0.625 7.5C0.625 7.98325 1.01675 8.375 1.5 8.375C1.98325 8.375 2.375 7.98325 2.375 7.5C2.375 7.01675 1.98325 6.625 1.5 6.625ZM13.5 6.625C13.0168 6.625 12.625 7.01675 12.625 7.5C12.625 7.98325 13.0168 8.375 13.5 8.375C13.9832 8.375 14.375 7.98325 14.375 7.5C14.375 7.01675 13.9832 6.625 13.5 6.625ZM3.125 7.5C3.125 6.2574 4.13235 5.25 5.375 5.25C6.61765 5.25 7.625 6.2574 7.625 7.5C7.625 8.7426 6.61765 9.75 5.375 9.75C4.13235 9.75 3.125 8.7426 3.125 7.5ZM9.625 7.5C9.625 6.2574 10.6324 5.25 11.875 5.25C13.1176 5.25 14.125 6.2574 14.125 7.5C14.125 8.7426 13.1176 9.75 11.875 9.75C10.6324 9.75 9.625 8.7426 9.625 7.5ZM5.375 6C4.5462 6 3.875 6.67125 3.875 7.5C3.875 8.32875 4.5462 9 5.375 9C6.2038 9 6.875 8.32875 6.875 7.5C6.875 6.67125 6.2038 6 5.375 6ZM11.875 6C11.0462 6 10.375 6.67125 10.375 7.5C10.375 8.32875 11.0462 9 11.875 9C12.7038 9 13.375 8.32875 13.375 7.5C13.375 6.67125 12.7038 6 11.875 6ZM1.75 7.5C1.75 7.43788 1.75675 7.37725 1.76925 7.31875L7.31875 5.01925C7.37 5.00675 7.42275 5 7.47575 5H7.52425C7.57725 5 7.63 5.00675 7.68125 5.01925L13.2308 7.31875C13.2433 7.37725 13.25 7.43788 13.25 7.5C13.25 7.56212 13.2433 7.62275 13.2308 7.68125L7.68125 9.98075C7.63 9.99325 7.57725 10 7.52425 10H7.47575C7.42275 10 7.37 9.99325 7.31875 9.98075L1.76925 7.68125C1.75675 7.62275 1.75 7.56212 1.75 7.5Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"/>
  </svg>
);

const SummaryIcon = () => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.69667 0.0403541C8.90859 0.131038 9.03106 0.354857 8.99316 0.582235L8.0902 6.00001H12.5C12.6893 6.00001 12.8625 6.10701 12.9472 6.27641C13.0319 6.4458 13.0136 6.6485 12.8999 6.80001L6.89997 14.8C6.76167 14.9844 6.51521 15.0503 6.30328 14.9597C6.09135 14.869 5.96888 14.6452 6.00678 14.4178L6.90974 9H2.49999C2.31061 9 2.13748 8.893 2.05278 8.72361C1.96809 8.55422 1.98636 8.35151 2.09999 8.2L8.09997 0.200038C8.23828 0.0156255 8.48474 -0.0503301 8.69667 0.0403541ZM3.49999 8.00001H7.49997C7.64695 8.00001 7.78648 8.06467 7.88148 8.17682C7.97648 8.28896 8.01733 8.43723 7.99317 8.5822L7.33027 12.5596L11.5 7.00001H7.49997C7.353 7.00001 7.21347 6.93534 7.11846 6.8232C7.02346 6.71105 6.98261 6.56279 7.00678 6.41781L7.66968 2.44042L3.49999 8.00001Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd" />
  </svg>
);


const debounce = (func: (...args: any[]) => void, wait: number) => {
  let timeout: NodeJS.Timeout | null = null;
  return (...args: any[]) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};


export function Home(props: any) {

  const {attributes,dbuser,setDbuser,signOut,token,loading}= useContext(UserContext);
  const [title,setTitle] = useState("");
  const {register, handleSubmit} = useForm();
  const [apiError, setApiError] = useState<string | null>(null);
  
  //wrapper
  const [wrapperTopPosition, setWrapperTopPosition] = useState(0);
  const wrapperRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const updateWrapperPosition = () => {
      if (wrapperRef.current) {
        const rect = wrapperRef.current.getBoundingClientRect();
        setWrapperTopPosition(rect.top + window.scrollY);
      }
    };

    updateWrapperPosition();
    window.addEventListener('resize', updateWrapperPosition);
    return () => window.removeEventListener('resize', updateWrapperPosition);
  }, []);

  //scroller
  const [expanded, setExpanded] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [maxScrollPosition, setMaxScrollPosition] = useState(0);

  const handleScroll = useCallback(
    debounce(() => {
      if (wrapperRef.current) {
        const rect = wrapperRef.current.getBoundingClientRect();
        const newScrollPosition = Math.max(0, window.scrollY - rect.top);
        setHasScrolled(window.scrollY > rect.top);
        setScrollPosition(newScrollPosition);
      }
    }, 10), // 10ms debounce time
    []
  );

  //look into later. dont know what this does
  const [wrapperWidth, setWrapperWidth] = useState(0);
  const [drawerExpanded, setDrawerExpanded] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (wrapperRef.current) {
        const rect = wrapperRef.current.getBoundingClientRect();
        const newScrollPosition = Math.max(0, window.scrollY - rect.top);
        setHasScrolled(window.scrollY > rect.top);
        setScrollPosition(newScrollPosition);

        // Calculate the maximum scroll position with 15% buffer
        const parentHeight = wrapperRef.current.clientHeight;
        const floatingBoxHeight = 200; // Adjust this value based on your FloatingBox height
        const bufferHeight = parentHeight * 0.15; // 15% of parent height
        const newMaxScrollPosition = parentHeight - floatingBoxHeight - bufferHeight;
        setMaxScrollPosition(newMaxScrollPosition);
      }
    };

    const updateWrapperDimensions = () => {
      if (wrapperRef.current) {
        const rect = wrapperRef.current.getBoundingClientRect();
        setWrapperWidth(rect.width);
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', updateWrapperDimensions);

    // Initial call to set dimensions and scroll position
    updateWrapperDimensions();
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', updateWrapperDimensions);
    };
  }, []);

  const toggleDrawer = () => {
    setDrawerExpanded(!drawerExpanded);
  };


  const [customLabels, setCustomLabels] = useState({
    SUM: 'Summary',
    S1: 'Introduction and Stasis',
    S2: 'Inciting Incident',
    S3: 'Comittment',
    S4: 'First Pinch Point',
    S5: 'Midpoint',
    S6: 'Second Pinch Point',
    S7: 'Second Plot Point',
    S8: 'Climax',
    S9: 'Resolution'
  });

  const [data, setData] = useState( {
    G: '',
    T: '',
    M: '',
    CQ: '',
    SUM: '',
    S1: '',
    S2: '',
    S3: '',
    S4: '',
    S5: '',
    S6: '',
    S7: '',
    S8: '',
    S9: '',
  });
  const zeroLength = () => {
    return (data.G.length + data.T.length + data.CQ.length + data.SUM.length 
      + data.S1.length + data.S2.length + data.S3.length + data.S4.length
       + data.S5.length + data.S6.length + data.S7.length + data.S8.length
        + data.S9.length);
    }
  

  const bannerContainerStyle = {
    width: '100%', 
    position: 'relative' as const, // Type assertion to avoid TypeScript error
    overflow: 'hidden',
    height: '150px', // Adjust this value as needed
  };
  const orangeShapeStyle = {
    content: '""',
    //background: 'linear-gradient(140deg, #fea57e 0%,#fea57e 35%, #FFFFFF 100%)',
    position: 'absolute' as const, // Type assertion
    top: 'auto',
    left: 'auto',
    width: '150%',
    height: '150px',
    //backgroundColor: '#FFFFFF', // Light orange color
    borderRadius: '0 0 0% 0%',
    zIndex: -1,
  };

  const clearField = (segmentId: string) => {
    setData((data) => ({ ...data, [segmentId]: "" }));
  
  };

  const clearAllFields = () => {
    setData((data) => ({...data,
      M: "",
      T: "",
      G: "",
      CQ: "",
      SUM: "",
      S1: "",
      S2: "",
      S3: "",
      S4: "",
      S5: "",
      S6: "",
      S7: "",
      S8: "",
      S9: "" 
    }));
  };
  const randomGenre = () => {
      const genres = ['Drama/Comedy', 'Drama/Thriller', 'Drama/War', 'Drama/Mystery', 'Drama/Arthouse'];
      const randomGenre = genres[Math.floor(Math.random() * genres.length)];
      return randomGenre;
  }
  
  //handler for summary submit
  const handleSummary = (e: any) => {
    const formData = new FormData(e.target);

    mutateSummary.mutate(formData);
  }
  //useMutation hook for summary
  const mutateSummary = useMutation({
    mutationFn:(formData: any) => {
      if (data.G.length == 0) {
        randomGenre();
      }
      return summary(formData);
      },
      onSuccess: (res: any) => {
        if (res.data.statusCode == 200) {
          setData((data) => ({ ...data, SUM: res.data.body.SUM }));
          setDbuser( (dbuser: any) => ({...dbuser, cap: res.data.body.cap}));
        } else if (res.data.statusCode == 400) {
            toast.error(res.data.body.error);
        } else {
          toast.error("error");
        }
      },
      onError: (error: any) => {
        toast.error("error");
    },
  });
  const summary = async (formData: any) => {
    let genre = data.G;
    if (genre.length == 0) {
      genre = randomGenre();
    }
    return await axios.post(`${process.env.REACT_APP_URL}/summary`,
      {
          "event": "summary",
          "userId": token?.payload['cognito:username'],
          "M": data.M,
          "T": data.T,
          "G": genre,
          "CQ": data.CQ,
          "SUM": data.SUM,
      },
      { headers: { "Authorization": token.toString()}}
      );
  } 
  //handler for story submit
  const handleStory = (e: any) => {
    if (zeroLength() == 0) {
      toast.error("Please fill out a field below");
    } else {
      const formData = new FormData(e.target);
      mutateStory.mutate(formData);
    }
  }
 

  //useMutation hook for story
  const mutateStory = useMutation({
    mutationFn:(formData: any) => {
      return story(formData);
      },
      onSuccess: (res: any) => {
        if (res.data.statusCode == 200) {
          setDbuser( (dbuser: any) => ({...dbuser, cap: res.data.body.cap}));
          setData((data) => ({ ...data,
            M:res.data.body.output.M,
            T:res.data.body.output.T,
            G:res.data.body.output.G,
            CQ:res.data.body.output.CQ,
            SUM:res.data.body.output.SUM,
            S1:res.data.body.output.S1,
            S2:res.data.body.output.S2,
            S3:res.data.body.output.S3,
            S4:res.data.body.output.S4,
            S5:res.data.body.output.S5,
            S6:res.data.body.output.S6,
            S7:res.data.body.output.S7,
            S8:res.data.body.output.S8,
            S9:res.data.body.output.S9
          }));

        } else if (res.data.statusCode == 400) {
            toast.error(res.data.body.error);
        } else {
          toast.error("error");
        }

      },
      onError: (error: any) => {
        toast.error("error");
      },
    });
 
  const story = async(formData: any) => {
    return await axios.post(`${process.env.REACT_APP_URL}/summary`, {
          "event": "story",
          "userId": token?.payload['cognito:username'],
          "M": data.M,
          "T": data.T,
          "G": data.G,
          "CQ": data.CQ,
          "SUM": data.SUM,
          "S1": data.S1,
          "S2": data.S2,
          "S3": data.S3,
          "S4": data.S4,
          "S5": data.S5,
          "S6": data.S6,
          "S7": data.S7,
          "S8": data.S8,
          "S9": data.S9
      },
      { headers: { "Authorization": token.toString()} }
    );
    }
  const handleSave = (e: any) => {
    if (title.length == 0) {
      toast.error("Please enter a title");
    } else {
      mutateSave.mutate(title);
    }
  }

  //useMutation hook for summary
  const mutateSave = useMutation({
    mutationFn:(title: string) => {
      return save(title);
      },
      onSuccess: (res: any) => {
        if (res.data.statusCode == 200) {
          toast.success("story saved!");
          setDbuser((dbuser: any) => ({...dbuser, works: res.data.body.works}));
          setTitle(title => "");

        } else if (res.data.statusCode == 400) {
            toast.error(res.data.body.error);
        } else {
          toast.error("error");
        }
      },
      onError: (error: any) => {
        toast.error("error");
      },
  });

  const save = async(title: string) => {
    return await axios.post(`${process.env.REACT_APP_URL}/works`, {
      "event": "save",
      "title": title,
      "userId":token?.payload['cognito:username'],
      "M": data.M,
      "T": data.T,
      "G": data.G,
      "CQ": data.CQ,
      "SUM": data.SUM,
      "S1": data.S1,
      "S2": data.S2,
      "S3": data.S3,
      "S4": data.S4,
      "S5": data.S5,
      "S6": data.S6,
      "S7": data.S7,
      "S8": data.S8,
      "S9": data.S9
    },
    { headers: { "Authorization": token.toString()} }
    );
  }

  const renderTextArea = (field: string, rows: number = 1, width: string = '100%') => (
      <TextArea
        id={field}
        field={field}
        rows={rows}
        width={width}
        value={data[field as keyof typeof data]}
        onChange={(event) => setData((data) => ({ ...data, [field]: event.target.value }))} 
        onClearField={clearField}
        customLabels={customLabels}
      />
    );
      
  if (loading == false) {
    return (
      <>
        <div
        style={{
          position: 'relative',
          left:'50%',
          top:'300px',
        }}   
        >
      <TailSpin  stroke="#FFA500" speed="1.3" />
        </div>
      </>
    );
  }
  else {
    return (
    <>
      <Theme>
      <Toaster
      position="top-center"
      reverseOrder={false}
      />
      <div className="gradient-background"></div>
        <Header/>
        <Flex direction="column" justify="center" align="center" style={{ flexGrow: 1, position: 'relative' }}>
        < div className= "banner-container">
        <div className= "orange-shape"></div>
        <img src={whiteOverlay} alt="White overlay" className = "white-overlay"/>
        <div
          style={{
            color: 'white',
            position: 'relative',
            left: '70%',
            top: '43%'
          }}
          >
          <Component1Icon />
          {dbuser.cap}
        </div>
        </div>
          <Flex style={{ width: '100%', justifyContent: 'center', position: 'relative', paddingTop: '50px' }}>
            <Flex style={{ width: '100%', justifyContent: 'center', position: 'relative' }}>
            <Flex direction="column" width="90%" maxWidth="1200px" align="center" className="text-areas-background" ref={wrapperRef}>
            <Box
                style={{
                  position: 'relative',
                  bottom:'20px',
                  backgroundColor: 'rgba(255, 255, 255, 0.2)',
                  borderRadius: '24px',
                  padding: '32px',
                  paddingTop: '40px',
                  backdropFilter: 'blur(10px)',
                  boxShadow: '0 12px 24px rgba(0, 0, 0, 0.2)',
                  border: '1px solid rgba(255, 255, 255, 0.3)',
                  width: '100%',
                  margin: '25px 0',
                  zIndex: 2,
                }}
            >
            <Box
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                height: '40px', // This should match the amount of drawer peeking out
                overflow: 'visible',
                zIndex: 3,
                justifyContent: 'center',
              }}
            >
          <FloatingBox 
            expanded={drawerExpanded} 
            onClearAllFields={clearAllFields}
            onToggle={toggleDrawer}
            isScrolled={hasScrolled && expanded}
            wrapperWidth={wrapperWidth}
            scrollPosition={scrollPosition}
            maxScrollPosition={maxScrollPosition}
          />                
          </Box>
          <Box 
            style={{ 
              position: 'relative', 
              zIndex: 2,
              marginTop: '-10px', // Negative margin to offset the peeking drawer
            }}
          >
          <form>
            <Container size="3" align="center">
              <Box className="white-container">
                <Flex direction="column" align="center" style={{ width: '100%' }}>
                  <Grid columns="2" justify="center" gap="4">
                    {renderTextArea('G', 1, '100%')}
                    {renderTextArea('T', 1, '100%')}
                    {renderTextArea('M', 3, '100%')}
                    {renderTextArea('CQ', 3, '100%')}
                  </Grid>
                </Flex>
              </Box>
            </Container>
            <Container size="3" align="center">
              <Box className="white-container">
                {renderTextArea('SUM', 3, '100%')}
                  <Button 
                    onClick={(e) => {handleSubmit(handleSummary)(e)}} 
                    type="submit" 
                    name="generate_summary" 
                    variant="solid" 
                    className="semi-transparent-button"
                    disabled={mutateSummary.isLoading}
                  >
                  <SummaryIcon />
                  {mutateSummary.isLoading ? 'Generating...' : 'Generate Summary'}
                  </Button>
              </Box>
              </Container>
                <Container size="4" align="center">
                  <Box className="wider-white-container">
                    {!expanded && (
                      <Flex justify="center" py="9">
                        <Button type="button" variant="solid" radius="full" className="semi-transparent-button" onClick={() => setExpanded(true)}>
                          <svg
                            width="30"
                            height="30"
                            viewBox="-2 0 15 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ marginRight: '0.5rem' }}
                          >
                            <path
                              d="M8 2.75C8 2.47386 7.77614 2.25 7.5 2.25C7.22386 2.25 7 2.47386 7 2.75V7H2.75C2.47386 7 2.25 7.22386 2.25 7.5C2.25 7.77614 2.47386 8 2.75 8H7V12.25C7 12.5261 7.22386 12.75 7.5 12.75C7.77614 12.75 8 12.5261 8 12.25V8H12.25C12.5261 8 12.75 7.77614 12.75 7.5C12.75 7.22386 12.5261 7 12.25 7H8V2.75Z"
                              fill="currentColor"
                              fillRule="evenodd"
                              clipRule="evenodd"
                            />
                          </svg>
                          Expand Story Segments
                        </Button>
                      </Flex>
                    )}
                    {expanded && (
                      <>
                        {Array.from({ length: 9 }, (_, i) => i + 1).map((i) => renderTextArea(`S${i}`, 4, '100%'))}
                      </>
                    )}
                  </Box>
                </Container>
                <Flex justify="start">
                  <Button onClick={(e) => {handleSubmit(handleSave)(e)}} 
                  name="generate_story" 
                  className="semi-transparent-button"
                  variant="solid">
                  Save Work
                  </Button>
                  <input  value={title} onChange={(e) => setTitle(e.target.value)} />     
                </Flex>
                <Flex justify="end">
                <Button 
                  onClick={(e) => {handleSubmit(handleStory)(e)}} 
                  name="generate_story" 
                  variant="solid" 
                  className="semi-transparent-button"
                  disabled={mutateStory.isLoading}
                >
                 {mutateStory.isLoading ? 'Generating...' : 'Generate Story'}
                </Button> 
                </Flex>
                </form>
              </Box>
            </Box> 
            </Flex>
           </Flex> 
           </Flex> 
          </Flex>
        <Footer/>
      </Theme>
    </>
  )};
}
  export default Home;

