import React from 'react';
import { Amplify } from 'aws-amplify';
import Header from './header';
import Footer from './footer';  
import {useDropzone} from 'react-dropzone';
import { useState , useEffect, useContext} from "react";
import {useForm} from "react-hook-form";
import { fetchUserAttributes } from 'aws-amplify/auth';
import { FetchUserAttributesOutput } from 'aws-amplify/auth';
import {updateUserAttribute,type UpdateUserAttributeOutput} from 'aws-amplify/auth';
import { getCurrentUser } from 'aws-amplify/auth';
import { useMutation } from "react-query";
import type { WithAuthenticatorProps } from '@aws-amplify/ui-react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import config from '../aws-exports';
import { Theme, Flex, Text, Button, Grid, TextArea ,TextField,Box} from '@radix-ui/themes';
import axios from 'axios';
import '@radix-ui/themes/styles.css';
import { DataList,Badge, Avatar } from '@radix-ui/themes';
import { ComponentPlaceholderIcon } from '@radix-ui/react-icons';
import { TailSpin } from 'react-loading-icons';
import {UserContext} from '../App';
import whiteOverlay from './Head-color with text.png';
import {toast,Toaster } from 'react-hot-toast';


import './profile.css';
Amplify.configure(config);


export function Profile(props:any) {
  const {dbuser, setDbuser,signOut,token,loading} = useContext(UserContext);
  const[changeUsername,setChangeUsername] = useState("");
  const[file, setFile] = useState<File | undefined>();
  const[preview, setPreview] = useState<string| ArrayBuffer | null>();
  const {register, handleSubmit} = useForm();

  //delete saved work
  const deleteWork = useMutation({
    mutationFn:(key:string) => {
      return handleDeletion(key);
      },
      onSuccess: (res: any) => {
        if (res.data.statusCode == 200) {
          toast.success("Work deleted!");
          setDbuser((dbuser: any) => ({...dbuser, works: res.data.body.works}));
        } else if (res.data.statusCode == 400) {
            toast.error(res.data.body.error);
        } else {
          toast.error("error");
        }
      },
      onError: (error: any) => {
        toast.error("error");
      },
    });
  
  const  handleDeletion = async(key: string) => {
    return await axios.post(`${process.env.REACT_APP_URL}/works`, {
      "event": "delete",
      "title": key,
      "userId": token?.payload['cognito:username'],
    }, 
    { headers: { "Authorization": token.toString()} }
  );
  }
  if (loading == false) {
    return (
      <>
        <div
        style={{
          position: 'relative',
          left:'50%',
          top:'300px',
        }}   
        >
      <TailSpin  stroke="#FFA500" speed="1.3" />
        </div>
      </>
    );
  } else {
    return (
      <>
        <Theme>
        <Toaster
          position="top-center"
          reverseOrder={false}
        />
          <div className="app-container">
          <div className="gradient-background"></div>
            <Header/>
            <Flex direction="column" justify="center" align="center" style={{ flexGrow: 1, position: 'relative' }}>
            < div className= "banner-container">
          <img src={whiteOverlay} className="white-overlay" alt="white-overlay" />
          </div>
      
            <Box className="white-container">
              <h2 style={{ textAlign: 'center' }}>Profile </h2>
              <DataList.Root>
                <DataList.Item>
                  <DataList.Label>Username</DataList.Label>
                  <DataList.Value>{token?.payload?.preferred_username}</DataList.Value>
                </DataList.Item>
                <DataList.Item>
                  <DataList.Label>Email</DataList.Label>
                  <DataList.Value>{token?.payload?.email}</DataList.Value>
                </DataList.Item>
                <DataList.Item>
                  <DataList.Label>Tokens</DataList.Label>
                  <DataList.Value>{dbuser.cap}</DataList.Value>
                </DataList.Item>
                <DataList.Item>
                  <DataList.Label>Subscription</DataList.Label>
                  <DataList.Value>
                    <Badge color="jade" variant="soft" radius="full">
                      {dbuser.subscription}
                    </Badge>
                  </DataList.Value>
                </DataList.Item>
                <DataList.Item>
                  <DataList.Label>Sign up date</DataList.Label>
                  <DataList.Value>{dbuser.sign_up_date}</DataList.Value>
                </DataList.Item>
              </DataList.Root>
            </Box>
          <Box className='white-container'
          style={{ width: '800px', height: 'fit-content', overflow: 'auto' }}
          
          >
            <h2 style={{ textAlign: 'center' }}>Works</h2>
            <ul style={{
              listStyleType:'none',
              marginRight: '40px', 
            }}>
                {
                  Object.keys(dbuser.works).map(key => (     
                  <div key={key}>
                    <Text as="label" style={{ fontFamily: "'Courier', monospace" }}> {key} </Text>
                    <li
                    style={{ 
                      width: '100%',
                      border: '2px solid rgba(0, 0, 0, 0.2)',
                      borderRadius: '8px',
                      marginBlockEnd: '3px',
                      transition: 'all 0.05s ease-in-oßt',
                      outline: 'none',
                      WebkitTapHighlightColor: 'transparent',
                      fontFamily: "'Courier', monospace",
                      boxShadow: 'none',}}
                      >
                      <Text>
                      {dbuser.works[key]?.SUM}
                      </Text>
                    </li>
                    <Button style={{ marginBlockEnd: '10px'}} onClick={() => deleteWork.mutateAsync(key)}>Delete</Button>
                  </div>
                  ))
                }
            </ul>
          </Box>
        </Flex>
        </div>
        <Footer/>
      </Theme>
      </>
    );
  }
}


export default Profile;
  