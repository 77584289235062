import { Amplify } from 'aws-amplify';
import type { WithAuthenticatorProps } from '@aws-amplify/ui-react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import config from '../aws-exports';
import {NavLink} from 'react-router-dom';
import { Text, Link, Theme,Flex } from '@radix-ui/themes';
import { EnvelopeClosedIcon } from '@radix-ui/react-icons';
import './footer.css';
Amplify.configure(config);


export function Footer({ signOut, user }: WithAuthenticatorProps) {
  return (
    <>
        <Theme>
              <Flex gap='8'
              style={{
                position: 'absolute',
                bottom: '0%',
                left: '27%',
                marginTop: '10px'

                }}
              >
                <Text className='rights'> &copy; 2024 FilmAssistant Inc. All rights reserved.</Text> 
                <Text className='name'> <EnvelopeClosedIcon /> accountservices@filmassistant.io</Text>
                <a  className='terms' href="https://google.com" target="_blank" rel="noopener noreferrer"><Text>Terms of Service</Text></a>
                </Flex>
        </Theme>
    </>
  )
}
export default withAuthenticator(Footer);