import React from 'react';
import './App.css';
import { useState , useEffect, createContext} from "react";
import { fetchUserAttributes, fetchAuthSession, AuthSession, JWT} from 'aws-amplify/auth';
import { FetchUserAttributesOutput } from 'aws-amplify/auth';
import type { WithAuthenticatorProps } from '@aws-amplify/ui-react';
import {withAuthenticator} from '@aws-amplify/ui-react';
import {BrowserRouter as Router,Routes,Route} from 'react-router-dom';
import Profile from './components/profile';
import Prices from './components/prices';
import Home from './components/home';
import NotFound from './components/notfound';
import '@aws-amplify/ui-react/styles.css';
import {Amplify} from 'aws-amplify';
import config from './aws-exports';
import '@radix-ui/themes/styles.css';
import axios from 'axios';
import Header from './components/header';
import Footer from './components/footer';
import { toast,Toaster } from 'react-hot-toast';

export const UserContext = createContext<any>({});

Amplify.configure(config);

function App({ signOut, user }: WithAuthenticatorProps) {
  const [attributes, setAttributes] = useState<FetchUserAttributesOutput>();
  const [token,setToken] = useState<JWT>();
  const [dbuser, setDbuser] = useState({
    cap: 0 ,
    subscription: "",
    sign_up_date: "",
    works: [{}],
  });
  const [loading,setLoading] = useState(false);

  //Getting user data from cognito
  useEffect(() => {
    async function handleFetchUserAttributes() {
      try {
        setAttributes(await fetchUserAttributes());
        const result = (await fetchAuthSession()).tokens?.idToken;
        setToken(result);
      } catch (error) {
        toast.error("error");
      }
    }
    handleFetchUserAttributes();
  }, []);

 //token refresh
  useEffect(() => {
    const tokenRefresh = setInterval( async() => {
      const result = (await fetchAuthSession()).tokens?.idToken;
      setToken(result);
    }, 1200000);
    return () => clearInterval(tokenRefresh);
  }, []);

  //Getting user data from dynamo db
  useEffect(() => {
    async function handleDynamoUser() {
      try {
        if (token) {
          const res = await axios.post(`${process.env.REACT_APP_URL}/user`, {
            email:  token?.payload.email,
            userId: token?.payload['cognito:username']
            },
            { headers: { "Authorization": token.toString()} }
          )
            setDbuser(d => (
              {...dbuser, 
                cap: res.data.body.cap,
                subscription: res.data.body.subscription ,
                sign_up_date: res.data.body.sign_up_date,
                works: res.data.body.works
            }));
            setLoading(true);
        }
      } catch (error) {
        toast.error("error");
      }
    }
    handleDynamoUser();
  }, [token]);

  
  return (
    <>
    <UserContext.Provider value ={{attributes,dbuser,setDbuser,signOut,token,setToken,loading,}}>
      <Router>
        <Routes>   
          <Route path="/" element={<Home attributes={attributes} dbuser={dbuser} setDbuser={setDbuser} signOut={signOut} />}></Route>
          <Route path="/profile" element={ <Profile attributes={attributes} dbuser={dbuser} setDbuser={setDbuser} /> }></Route>
          <Route path="/prices" element={ <Prices attributes={attributes} dbuser={dbuser} signOut={signOut} /> }></Route>
          <Route path ='*' element={ <NotFound /> }></Route>
        </Routes>
      </Router>
    </UserContext.Provider>
    </>
  );
}

export default withAuthenticator(App);
