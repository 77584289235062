import React from 'react';
import Stripe from 'stripe';
import {useStripe} from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import redirectToCheckout  from 'stripe';
import { Amplify } from 'aws-amplify';
import { useMutation } from "react-query";
import { useContext } from 'react';
import Header from './header';
import Footer from './footer';
import type { WithAuthenticatorProps } from '@aws-amplify/ui-react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import {BrowserRouter as Router,Routes,Route,NavLink} from 'react-router-dom';
import { Theme, Flex, Text, Button, Grid, Box,Container,Card, Inset } from '@radix-ui/themes';
import whiteOverlay from './Head-color with text.png';
import { Link } from 'react-router-dom';
import axios from 'axios';
import '@aws-amplify/ui-react/styles.css';
import config from '../aws-exports';
import {UserContext} from '../App';
import { Check, Settings } from 'lucide-react';
import { ComponentPlaceholderIcon } from '@radix-ui/react-icons';
import { TailSpin } from 'react-loading-icons';
import { toast,Toaster } from 'react-hot-toast';
import  './prices.css'
Amplify.configure(config);



export function Prices(props:any) {  
  const {dbuser, setDbuser,signOut,token,loading} = useContext(UserContext);
  
  const subscribe = useMutation({
    mutationFn:() => {
      return member();
      },
      onSuccess: (res: any) => {
        window.open(res.data.headers.Location,"_self");
      },
      onError: (error: any) => {
        toast.error("error");
      },
    });
  const refill = useMutation({
    mutationFn:() => {
      return base();
      },
      onSuccess: (res: any) => {
        window.open(res.data.headers.Location,"_self");
      },
      onError: (error: any) => {
        toast.error("error");
      },
    });
  const refill_discount = useMutation({
    mutationFn:() => {
      return discount();
      },
      onSuccess: (res: any) => {
        window.open(res.data.headers.Location,"_self");
      },
      onError: (error: any) => {
        toast.error("error");
      },
    });
  const member = async() => {
    return await axios.post(`${process.env.REACT_APP_URL}/checkout`, {
          "event": "member",
          "email":token?.payload.email,
          "userId": token?.payload['cognito:username']
      },
      { headers: { "Authorization": token.toString()} }
    );
    
    }
  const base = async() => {
    return await axios.post(`${process.env.REACT_APP_URL}/checkout`, {
          "event": "base",
          "email":token?.payload.email,
          "userId": token?.payload['cognito:username']
      },
      { headers: { "Authorization": token.toString()} }
    );
    
    }
  const discount = async() => {
    return await axios.post(`${process.env.REACT_APP_URL}/checkout`, {
          "event": "discount",
          "email": token?.payload.email,
          "userId": token?.payload['cognito:username']
      },
      { headers: { "Authorization": token.toString()} }
    );
    
    }
  return (
      <>
        { !loading &&
          <div
          style={{
            position: 'relative',
            left:'50%',
            top:'300px',
          }}   
          >
        <TailSpin  stroke="#FFA500" speed="1.3" />
          </div>
        }
        { dbuser.subscription == "free" &&
          <Theme>
          <Toaster
          position="top-center"
          reverseOrder={false}
          />
            <div className="gradient-background"></div>
              <Header/>           
              < div className= "banner-container">
              {/* CSS Shape (Orange Underlay) */}
              <div className= "orange-shape"></div>
                {/* White Overlay (Image 2) */}
                <img src={whiteOverlay} alt="White overlay" className = "white-overlay"/>
            </div>
            <Flex justify="center" gap="8" flexBasis='100%'>
            <Box 
                style= {{
                  width: "400px",
                  height: "400px",
                }}
                className="white-container">
                <h2 style={{ textAlign: 'left' }}>Member</h2>
                <h3 style={{ textAlign: 'left' }}>$4.00/mo </h3>
                <ul style={{ listStyleType:'none',}}>
                  <li>
                    <Check 
                    style={{color: 'green'}} />
                    <Text> ~75 generations per month</Text>
                  </li>
                  <li>
                    <Check 
                    style={{color: 'green'}} />
                    <Text> Access to Community/Weekly Contests</Text>
                  </li>
                  <li>
                    <Check 
                    style={{color: 'green'}} />
                    <Text> Better Rate on Tokens </Text>
                  </li>
                  <li>
                    <Check 
                    style={{color: 'green'}} />
                    <Text> Cheaper Token Refills </Text>
                  </li>
                </ul>
                <br></br>
                  <Button  
                  style={{
                    width: '150px',
                    position: 'relative',
                    left: '28%',
                    top: '15px',
                    marginTop: '10px'
                  }}
                  variant="solid" onClick={() => {
                  subscribe.mutateAsync().catch(() => toast.error("error"));
                }}>Buy Plan
                </Button>
            </Box>
            <Box 
                style= {{
                  width: "400px",
                  height: "400px",

                }}
                className="white-container">
                <h2 style={{ textAlign: 'left' }}>Base Token Package</h2>
                <h3 style={{ textAlign: 'left' }}>$5.00 </h3>
                <ul style={{ listStyleType:'none',}}>
                  <li>
                    <Check 
                    style={{color: 'green'}} />
                    <Text> ~75 generations</Text>
                  </li>
                  <li>
                    <Check 
                    style={{color: 'green'}} />
                    <Text> create more stories </Text>
                  </li>
                  <li>
                    <Check 
                    style={{color: 'green'}} />
                    <Text> create more summaries</Text>
                  </li>
                  <li>
                    <Check 
                    style={{color: 'green'}} />
                    <Text> One time purchase</Text>
                  </li>
                </ul>
                <br></br>
                  <Button  
                  style={{
                    width: '150px',
                    position: 'relative',
                    left: '28%',
                    top: '15px',
                    marginTop: '10px'
                  }}
                  variant="solid" onClick={() => {
                  refill.mutateAsync().catch(() => toast.error("error"));
                }}>Buy Tokens 
                </Button>
              </Box>
            </Flex>
            <Footer/>
          </Theme> }
        { dbuser.subscription=="member" &&
          <Theme>
              <Toaster
              position="top-center"
              reverseOrder={false}
              />
              <div className="gradient-background"></div>
                <Header/>           
                < div className= "banner-container">
                {/* CSS Shape (Orange Underlay) */}
                <div className= "orange-shape"></div>
                    {/* White Overlay (Image 2) */}
                    <img src={whiteOverlay} alt="White overlay" className = "white-overlay"/>
              </div>
                <Button  
                style={{
                  position:'relative',
                  left: '80%',
                  bottom:'20px',
                }}
                variant="solid" 
                onClick={() => {
                    window.open("https://billing.stripe.com/p/login/test_fZe9COchwdlU7ok5kk","_self")
                }}> 
                <Settings style={{width:'16px', height: '16px'}} />
                Manage Subscription
              </Button>         
              <br></br>    
              <Flex justify="center">   
                <Box 
                style= {{
                  width: "fit-content",
                }}
                className="white-container">
                <h2 style={{ textAlign: 'left' }}>Member Token Refill </h2>
                <h3 style={{ textAlign: 'left' }}>$2.00 </h3>
                <Check 
                style={{color: 'green'}} />
                <Text>Member Access Only: ~75 more generations</Text>
                <br></br>
                  <Button  
                  style={{
                    width: '150px',
                    position: 'relative',
                    left: '28%',
                    top: '15px',
                    marginTop: '10px'
                  }}
                  variant="solid" onClick={() => {
                    refill_discount.mutateAsync().catch(() => toast.error("error"));
                  }}>Buy Now</Button>
                </Box>
              </Flex>                
              <Footer/>
            </Theme>}    
      </>
  );
}
  export default Prices;